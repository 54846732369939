<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR MI PERFIL </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'PerfilUsuarioPanel' }"
                    >Mi perfil</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Correo(*)"
                    v-model="usuario.correo"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    :append-icon="showIptPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showIptPassword ? 'text' : 'password'"
                    label="Contraseña (*)"
                    v-model="usuario.clave"
                    @click:append="showIptPassword = !showIptPassword"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      usuario: {
        _method: "PUT",
        idusuario: "",
        correo: "",
        clave: "",
      },
      showIptPassword: false,
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  methods: {
    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/perfilUsuario",
        params: {
          idusuario: this.$session.get("usuario").idusuario,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let usuario = response.data.data;

            this.usuario.idusuario = usuario.idusuario;
            this.usuario.correo = usuario.correo;
            this.usuario.clave = usuario.clave;
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router
            .push({
              name: "PerfilUsuarioPanel",
            })
            .catch(() => {});
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;
      this.axios({
        method: "POST",
        url: "api/panel/perfilUsuario/" + this.usuario.idusuario,
        data: this.serialize(this.usuario),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router
              .push({
                name: "PerfilUsuarioPanel",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
